<template>
  <div class="general">
    <div class="general__top">
      <TotalResultPill :count="generalResultsCount" />
      <div class="general__text" :class="{ 'general__text--empty': !generalSearchText }">
        <h3>"{{ generalSearchText }}"</h3>
      </div>
      <div class="general__categories">
        <div
          :class="
            currentCategory === 'alle'
              ? 'general__categories--selected'
              : 'general__categories--active'
          "
          @click="selectCategory('alle')"
        >
          <p class="general__categories__category">
            {{ $t('all') }}<span>({{ generalResultsCount }})</span>
          </p>
          <span class="general__categories__category__underline" />
        </div>
        <div
          v-for="category in indexes"
          :key="category.key"
          :class="
            currentCategory === category.key
              ? 'general__categories--selected'
              : `general__categories--${
                  generalResults?.find(
                    (result) => result.index === category.key,
                  )?.nbHits
                    ? 'active'
                    : 'disabled'
                }`
          "
          @click="selectCategory(category.key)"
        >
          <p class="general__categories__category">
            {{ category.label }}
            <span>
              ({{
                generalResults?.find((result) => result.index === category.key)?.nbHits
              }})
            </span>
          </p>
          <span class="general__categories__category__underline" />
        </div>
      </div>
    </div>
    <ResultsSection :hasResultsInSingleCategory="hasResultsInSingleCategory" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TotalResultPill from '@/components/TotalResultPill.vue'
import ResultsSection from '@/components/ResultsSection.vue'

export default {
  components: {
    TotalResultPill,
    ResultsSection,
  },
  mounted() {
    if (this.hasResultsInSingleCategory) this.$store.commit('setCurrentCategory', this.generalResults.find(category => category.nbHits > 0).index)
  },
  computed: {
    ...mapGetters([
      'indexes',
      'currentCategory',
      'categoryResultsCount',
      'generalSearchText',
      'generalResults',
      'generalResultsCount',
    ]),
    hasResultsInSingleCategory() {
      // if only one category has results
      const resultsArray = this.generalResults.map(category => category.nbHits)
      return resultsArray.filter(result => result !== 0).length === 1
    }
  },
  watch: {
    hasResultsInSingleCategory() {
      if (this.hasResultsInSingleCategory) this.$store.commit('setCurrentCategory', this.generalResults.find(category => category.nbHits > 0).index)
    },
    generalResultsCount() {
      // No results
      if (this.generalResultsCount === 0) this.$store.commit('setCurrentCategory', 'alle')
    }
  },
  methods: {
    selectCategory(categoryKey) {
      this.$store.commit('setCurrentCategory', categoryKey)
    },
  },
}
</script>

<style lang="scss">
.general {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - $navbar-height);

  &__top {
    padding: 20px 16px;

    @include mq($from: mobile, $until: tablet) {
      padding: 40px 30px;
    }

    @include mq($from: tablet) {
      padding: 80px 65px 50px;
    }
  }

  &__text {
    border-bottom: 1px solid $color-grey-400;
    margin: 20px 0 34px;

    @include mq($from: mobile) {
      margin: 20px 0 60px;
    }

    &--empty {
      .elf & {
        border-bottom: none;
        margin-bottom: 0;

        h3 {
          display: none;
        }
      }
    }

    h3 {
      display: inline-flex;
      border-bottom: 3px solid $color-primary;
      padding: 14px 0;
      max-width: 100%;
      overflow: auto;
    }
  }

  &__categories {
    display: flex;
    gap: 16px;
    overflow: auto;
    padding-bottom: 12px;

    @include mq($from: mobile) {
      gap: 26px;
      flex-wrap: wrap;
      padding-bottom: 0;
    }

    &__category {
      font-size: 14px;
      font-weight: $font-weight-medium;
      white-space: nowrap;

      @include mq($from: mobile) {
        font-size: 18px;
      }

      span {
        font-size: 15px;
        font-weight: $font-weight-regular;
        margin-left: 4px;
      }
    }

    &--selected {
      color: $color-primary;
      border-bottom: 2px solid $color-primary;

      .general__categories__category span {
        color: $color-primary;
      }
    }

    &--active {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        color: $color-primary;

        .general__categories__category span {
          color: $color-primary;
        }

        .general__categories__category__underline {
          transform: scaleX(1);
          width: 100%;
        }
      }

      .general__categories__category__underline {
        background-color: $color-primary;
        height: 2px;
        transition: all 0.3s ease;
        transform: scaleX(0);
        transform-origin: left center;
        width: 0;
      }
    }

    &--disabled {
      color: $color-grey-500;
      pointer-events: none;

      .general__categories__category span {
        color: $color-grey-500;
      }
    }
  }
}
</style>
